import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["buttons", "tab"];

  showIndexedTab() {
    this.tabTargets.forEach((el, i) => {
      if (this.index == i) {
        el.classList.remove("hidden");
      } else {
        el.classList.add("hidden");
      }
    });

    this.buttonsTargets.forEach((el, i) => {
      el.classList.toggle("text-blue-500", this.index == i);
    });
  }

  initialize() {
    this.showIndexedTab();
  }

  activateTab(event) {
    this.index = Array.prototype.indexOf.call(
      event.target.parentNode.children,
      event.target
    );
  }

  get index() {
    return parseInt(this.data.get("index"));
  }

  set index(value) {
    this.data.set("index", value);
    this.showIndexedTab();
  }
}
