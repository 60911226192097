// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
import { Controller } from "stimulus";
import Plyr from "plyr";

// For saving story choices
import Rails from "@rails/ujs";

export default class extends Controller {
  profileData = {
  };

  static targets = ["list"];

  connect() {
    if (document.getElementById("video-player-wrapper")) {
      const player = new Plyr("#video-player-wrapper");
    }
  }

  disconnect() {
    console.log("resource page disconnected");
  }
}
