import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal"];

  close() {
    document.getElementById("defaultModal").classList.toggle("hidden");
  }
  
  connect(){
    document.getElementById("defaultModal").classList.remove("hidden");
    document.getElementById("defaultModal").classList.add("visible");
  }

}
