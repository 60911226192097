// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
import { Controller } from "stimulus";
import csc from 'country-state-city';

// For saving story choices
import Rails from "@rails/ujs";

export default class extends Controller {
  profileData = {};

  static targets = [
    'container',
    'experienceSubmit',
    'experienceForm',
    'ageAndGenderSubmit',
    'ageAndGenderForm',
    'racesSubmit',
    'racesForm',
    'countryAndStateSubmit',
    'sexEducationSubmit',
    'sexEducationSatisfySubmit',
    'sexualOrientationSubmit',
    'sexualActivitySubmit',
    'submitCompleteForm',
  ];

  connect() {
    this.profileData = $(this.containerTarget).data('meta');
    $(".profile-carousel")
      .not(".slick-initialized")
      .slick({
        arrows: false,
        infinite: false,
        autoplay: false,
        draggable: true,
        touchMove: false,
        dots: true,
      })
      .on("afterChange", function (event, slick, currentSlide) {
        if (currentSlide < 2) {
          $('.profile-carousel').addClass('without-dots');
        } else {
          $('.profile-carousel').removeClass('without-dots');
        }
      });

    $('select.nice-select').niceSelect();

    // Bind countries to the country select option
    let allCountries = csc.getAllCountries().sort(function(a, b) {
      return a.name == 'United States'
        ? -1
        : b.name == 'United States'
        ? 1
        : a.name < b.name ? -1 : 1;
    });
    const countrySelector = $('select[name="country"]').eq(0);
    allCountries.forEach((country, index) => {
      countrySelector.append(`<option value="${country.name}">${country.name}</option>`);
    });
    $('select[name="country"]').niceSelect();
  }

  disconnect() {
    console.log("profile page disconnected");
  }

  
  
  /**
   * Next Slide
   */
  next() {
    $('.profile-carousel').slick('slickNext');
  }
  
  /**
   * Previous Slide
   */
  prev() {
    $('.profile-carousel').slick('slickPrev');
  }

  /**
   * Go to nth Slide
   */
  goToSlide(n) {
    $('.profile-carousel').slick('slickGoTo', n);
  }

  /**
   * Submit profile
   */
  async submitData(callback = null) {
    try {
      let res = await fetch(
        'submit',  /* This right here */
        {
          method: "POST",
          headers: { 'X-CSRF-Token': Rails.csrfToken(), 'Content-Type': 'application/json'},
          body: JSON.stringify(this.profileData)
        }
      );
      this.profileData = {}

      if (callback == null) {
        if (res.status === 200) {
          this.next();
        }
      } else {
        callback();
      }

    } catch (err) {
    }
  }

  /**
   * Change Experience event
   */
  changeExperienceOption(event) {
    let checkedValue = [];
    $("input.experience_checkbox:checked").each(function(i) {
      checkedValue.push($(this).val());
    });
    if (checkedValue.length > 0) {
      this.profileData.experience = checkedValue;
      this.experienceSubmitTarget.removeAttribute('disabled');
    } else {
      this.experienceSubmitTarget.setAttribute('disabled', '');
    }
  }

  async submitExperience(event) {
    event.preventDefault();
    await this.submitData();
  }

  async skipExperience(event) {
    await this.submitData();
  }

  async finishExperience() {
    await this.submitData();
  }

  
  /**
   * Change Age event
   */
  changeAgeOption(event) {
    let val = $("input[name=age]:checked").val();
    if (val && val != '') {
      this.profileData.age = val;
    }
    this.checkValidityAgeAndGenderForm();
  }
  
  /**
   * Change Gender event
   */
  changeGenderOption(event) {
    if ($(event.target).data('type') === 'other_input') {
      $('input[name=gender]')
        .filter(`[value="Other"]`)
        .prop('checked', true);
    } else if(this.getGenderValue(true) !== 'Other') {
      $("input[name=gender_other]").val('');
    }
    let val = this.getGenderValue();
    if (val && val != '') {
      this.profileData.gender = val;
    }
    this.checkValidityAgeAndGenderForm();
  }

  getGenderValue(onlyCheckValue = false) {
    let gender = $("input[name=gender]:checked").val();
    if (gender === 'Other' && !onlyCheckValue) {
      return $("input[name=gender_other]").val();
    }
    return gender;
  }

  /**
   * Check validity of Age&Gender Form
   */
  checkValidityAgeAndGenderForm() {
    let age = $("input[name=age]:checked").val();
    let gender = this.getGenderValue();
    if (age && age != ''
      && gender && gender != ''
    ) {
      this.ageAndGenderSubmitTarget.removeAttribute('disabled');
    } else {
      this.ageAndGenderSubmitTarget.setAttribute('disabled', '');
    }
  }

  /**
   * Submit Age&Gender Form
   */
  async submitAgeAndGender(event) {
    event.preventDefault();
    await this.submitData();
  }

  /**
   * Change Races event
   */
  changeRacesOption(event) {
    let checkedValue = [];
    $("input.races_checkbox:checked").each(function(i) {
      checkedValue.push($(this).val());
    });
    if (checkedValue.length > 0) {
      this.profileData.races = checkedValue;
      this.racesSubmitTarget.removeAttribute('disabled');
    } else {
      this.racesSubmitTarget.setAttribute('disabled', '');
    }
  }

  /**
   * Submit Races Form
   */
  async submitRacesForm(event) {
    event.preventDefault();
    await this.submitData();
  }

  
  /**
   * Change Country event
   */
  changeCountryOption(event) {
    let val = $("select[name=country]").eq(0).val();
    $('select[name="state"]').eq(0).val(null);
    if (val && val != '') {
      this.bindStateOptions();
      this.profileData.country = val;
      $('.state-selecter-wrapper').show();
    }
    this.checkValidityCountryAndStateForm();
  }

  bindStateOptions() {
    const stateSelector = $('select[name="state"]').eq(0);
    const stateLabelHtml = stateSelector.find('option[disabled]').html();
    stateSelector.empty();
    stateSelector.append('<option value="" selected disabled>' + stateLabelHtml + '</option>');

    let country = $("select[name=country]").eq(0).val();
    const allCountries = csc.getAllCountries();
    let countryAlts = allCountries.filter((countryItem) => {
      return countryItem['name'] == country;
    });
    if (countryAlts.length < 1) {
      return;
    }
    let countryId = countryAlts[0]['id'];
    let states = csc.getStatesOfCountry(countryId);
    states.forEach((state, index) => {
      stateSelector.append(`<option value="${state.name}">${state.name}</option>`);
    });
    $('select[name="state"]').niceSelect('destroy');
    $('select[name="state"]').niceSelect();
  }
  
  /**
   * Change State event
   */
  changeStateOption(event) {
    let val = $("select[name=state]").eq(0).val();
    if (val && val != '') {
      this.profileData.state = val;
    }
    this.checkValidityCountryAndStateForm();
  }

  /**
   * Check validity of CountryAndState Form
   */
  checkValidityCountryAndStateForm() {
    let country = $("select[name=country]").eq(0).val();
    let state = $("select[name=state]").eq(0).val();
    if (country && country != ''
      && state && state != ''
    ) {
      this.countryAndStateSubmitTarget.removeAttribute('disabled');
    } else {
      this.countryAndStateSubmitTarget.setAttribute('disabled', '');
    }
  }

  /**
   * Submit Age&Gender Form
   */
  async submitCountryAndStateForm(event) {
    event.preventDefault();
    await this.submitData();
  }


  /**
   * Change Sex Education event
   */
  changeSexEducationOption(event) {
    let radioValue = $("input[name=sex_education]:checked").val();
    if (radioValue) {
      this.profileData.sex_education = radioValue;
      this.sexEducationSubmitTarget.removeAttribute('disabled');
    } else {
      this.sexEducationSubmitTarget.setAttribute('disabled', '');
    }
  }

  async submitSexEducationForm(event) {
    event.preventDefault();
    await this.submitData();
  }


  /**
   * Change Sex Education Satisfy event
   */
  changeSexEducationSatisfyOption(event) {
    let radioValue = $("input[name=sex_education_satisfy]:checked").val();
    if (radioValue) {
      this.profileData.sex_education_satisfy = radioValue;
      this.sexEducationSatisfySubmitTarget.removeAttribute('disabled');
    } else {
      this.sexEducationSatisfySubmitTarget.setAttribute('disabled', '');
    }
  }

  async submitSexEducationSatisfyForm(event) {
    event.preventDefault();
    await this.submitData();
  }


  /**
   * Change Sex Education Satisfy event
   */
  changeSexualOrientationOption(event) {
    let radioValue = $("input[name=sexual_orientation]:checked").val();
    if (radioValue) {
      this.profileData.sexual_orientation = radioValue;
      this.sexualOrientationSubmitTarget.removeAttribute('disabled');
    } else {
      this.sexualOrientationSubmitTarget.setAttribute('disabled', '');
    }
  }

  async submitSexualOrientationForm(event) {
    event.preventDefault();
    await this.submitData();
  }


  /**
   * Change Sexual Activity event
   */
  changeSexualActivityOption(event) {
    let radioValue = $("input[name=sexual_activity]:checked").val();
    if (radioValue) {
      this.profileData.sexual_activity = radioValue;
      this.sexualActivitySubmitTarget.removeAttribute('disabled');
    } else {
      this.sexualActivitySubmitTarget.setAttribute('disabled', '');
    }
  }

  async submitSexualActivityForm(event) {
    event.preventDefault();
    // this.profileData.step = 9;

    await this.submitData();
  }

  async submitCompleteForm(event) {
    event.preventDefault();
    const redirectPath = $(this.submitCompleteFormTarget).data('afterpath');
    await this.submitData(() => {
      location.href = redirectPath;
    });
  }
}
