// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    console.log("My programs component connected");
    $(".programs-recommended")
      .not(".slick-initialized")
      .slick({
        arrows: true,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        mobileFirst: true,
        infinite: false,
        autoplay: false,
        draggable: true,
        touchMove: true,
        dots: false,

        responsive: [
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 1536,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3.5,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1,
            },
          },
        ],
      });

    $(".programs-carousel")
      .not(".slick-initialized")
      .slick({
        arrows: true,
        infinite: false,
        autoplay: false,
        draggable: true,
        touchMove: true,
        dots: false,
        mobileFirst: true,
        adaptiveHeight: false,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 1536,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3.5,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1,
            },
          },
        ],
      });
  }

  disconnect() {
    console.log("My programs component disconnected");
  }

  toggleDrawerMenu() {}
}
