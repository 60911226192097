// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
  }

  disconnect() {
  }

  toggleSideBarMenu() {
    const container = $('.sidebar');
    const backdrop = $('.sidebar-backdrop');
    if (container.hasClass('opened')) {
        container.removeClass('animate');
        container.addClass('animate-backward');
        setTimeout(() => {
            container.removeClass('opened');
            backdrop.removeClass('opened');
        }, 250, container);
    } else {
        container.addClass('opened');
        backdrop.addClass('opened');
        container.removeClass('animate-backward');
        container.addClass('animate');
    }
  }
}
