
// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
import { Controller } from "stimulus"

// For saving story choices  
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "choice", //all possible choices for this conversation
        "prompt", //all prompts in this conversation
        "messageViewer", // where we show conversations
        "typingIndicator", //Need to insert before this. 
        "conversation", //We need to decrement / increment counter based on branching
        "pathStorage", //where we have all the paths 
        "confirmWarning" // records response to content warning
    ]

    initialize() {
      this.popPositions = []
    }

  loadFragment(event) {

    const element = event.target
    const where = element.dataset.pathId
    const hidePath = element.dataset.pathHideId
    const label = element.innerText
    const prompt = this.promptTargets.find((e) => e.contains(element))
    const promptLabel = prompt.querySelector('.prompt-label') ? prompt.querySelector('.prompt-label').innerText : "No prompt"
    // General idea:
    // If hiding current path, then just ignore the target (e.g. CHOICELAND), and just jump back.
    // increment the counter by one so people can see the place they jumped off. 
    // Otherwise, go to the named path. 
    
    if (hidePath) {
      // TODO is it current path?
      
      this.hideNamedPath(hidePath)
      // find last choice - which is where we branched off
      const branches = this.messageViewerTarget.querySelectorAll('.choice')
      const lastBranch = branches[branches.length-1]
      lastBranch.dataset.choiceCompleted = "false"
      
    }
    else {

      
      const loadElement = this.choiceTargets.find((e) => {return e.dataset.id == where})
      if(loadElement.dataset.loaded !== "loaded") {
        this.messageViewerTarget.insertBefore(loadElement, this.typingIndicatorTarget)
        this.recordChoice({choice_made: where, prompt: promptLabel, label: label})
      }
      loadElement.dataset.loaded = "loaded"
    }
  }


  hideNamedPath(hidePath) {
    if (hidePath) {
      const elementToHide = this.choiceTargets.find((e) => { return e.dataset.id == hidePath })
      //count how many story elements there are
      const storyElements = elementToHide.children.length
      //decrement the counter
      
      //remove the path
      if (elementToHide) {
        elementToHide.dataset.loaded = "false"
        for (const c of elementToHide.children) {
          c.classList.add('hidden')
          if (c.dataset.choiceCompleted == "completed") { c.dataset.choiceCompleted = "false"} 
        }
      }
      this.pathStorageTarget.appendChild(elementToHide)
      this.conversationTarget.dataset.conversationIndex -= (+storyElements+1)
    }
  }

  confirmWarning(event) {
    const warningChoice = event.target.innerText
    const prompt = "Content Warning"
    this.recordChoice({prompt: prompt, label: warningChoice})
  }

  async recordChoice(data) {
    return await fetch(this.data.get('record-choice-url'), { method: "POST", headers: { 'X-CSRF-Token': Rails.csrfToken(), 'Content-Type': 'application/json'}, body: JSON.stringify(data) })
  }
}
