import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "mobileMenu"]

    hide() {
        this.mobileMenuTarget.classList.remove('ease-in', 'opacity-100', 'z-50', 'scale-100')
        this.mobileMenuTarget.classList.add('ease-out', 'opacity-0', 'z-0', 'scale-95')
    }

    show() {
        this.mobileMenuTarget.classList.add('ease-in', 'opacity-100', 'z-50', 'scale-100')
        this.mobileMenuTarget.classList.remove('ease-out', 'opacity-0', 'z-0', 'scale-95')
    }
}