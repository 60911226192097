// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
import { Controller } from "stimulus";
import csc from 'country-state-city';

// For saving story choices
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
  ];

  connect() {
    $(".home-carousel")
      .not(".slick-initialized")
      .slick({
        arrows: false,
        infinite: false,
        autoplay: false,
        draggable: true,
        touchMove: false,
        dots: true,
      })
      .on("afterChange", function (event, slick, currentSlide) {
        if (currentSlide < 2) {
          $('.home-carousel').addClass('without-dots');
        } else {
          $('.home-carousel').removeClass('without-dots');
        }
      });
  }

  disconnect() {
    console.log("profile page disconnected");
  }

  gotoNextStep(e) {
    e.preventDefault();

    this.next();
  }

  gotoSignUp(e) {
    e.preventDefault();
    
    location.href = '/users/sign_up';
    // location.href = 'https://thenewsexed.org/campus-chat/';

  }

  /**
   * Next Slide
   */
  next() {
    $('.home-carousel').slick('slickNext');
  }
  
  /**
   * Previous Slide
   */
  prev() {
    $('.home-carousel').slick('slickPrev');
  }

  /**
   * Go to nth Slide
   */
  goToSlide(n) {
    $('.home-carousel').slick('slickGoTo', n);
  }

}
