// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";
import AWN from "awesome-notifications";
const notifier = new AWN({
  durations: {
    global: 3000,
  },
  icons: {
    enabled: true,
  },
  labels: {
    info: "",
    warning: "",
  },
});

export default class extends Controller {
  static targets = ["notifications"];

  connect() {
    const alerts = this.notificationsTarget.querySelectorAll(".alert");
    alerts.forEach((alert) => {
      notifier.warning(alert.innerText);
    });
    const notices = this.notificationsTarget.querySelectorAll(".notice");
    notices.forEach((notice) => {
      notifier.info(notice.innerText);
    });
  }
}
