import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["footer-tabs"];

  homePart() {
    document.getElementById("phone-single-wrapper").classList.remove("d-none");
    document.getElementById("story-part").classList.add("d-none-md");
    document.getElementById("featuring").classList.add("d-none-md");
    document.getElementById("links-resources").classList.add("d-none-md");
    document.getElementById("home-tab-icon").classList.remove("inactive-tab");
    document.getElementById("story-tab-icon").classList.add("inactive-tab");
    document.getElementById("char-tab-icon").classList.add("inactive-tab");
    document.getElementById("info-tab-icon").classList.add("inactive-tab");
  }

  storyPart() {
    document.getElementById("phone-single-wrapper").classList.add("d-none");
    document.getElementById("story-part").classList.remove("d-none-md");
    document.getElementById("featuring").classList.add("d-none-md");
    document.getElementById("links-resources").classList.add("d-none-md");
    document.getElementById("home-tab-icon").classList.add("inactive-tab");
    document.getElementById("story-tab-icon").classList.remove("inactive-tab");
    document.getElementById("char-tab-icon").classList.add("inactive-tab");
    document.getElementById("info-tab-icon").classList.add("inactive-tab");
  }

  featuring() {
    document.getElementById("phone-single-wrapper").classList.add("d-none");
    document.getElementById("featuring").classList.remove("d-none-md");
    document.getElementById("story-part").classList.add("d-none-md");
    document.getElementById("links-resources").classList.add("d-none-md");
    document.getElementById("home-tab-icon").classList.add("inactive-tab");
    document.getElementById("story-tab-icon").classList.add("inactive-tab");
    document.getElementById("char-tab-icon").classList.remove("inactive-tab");
    document.getElementById("info-tab-icon").classList.add("inactive-tab");
  }

  linksRresources() {
    document.getElementById("phone-single-wrapper").classList.add("d-none");
    document.getElementById("links-resources").classList.remove("d-none-md");
    document.getElementById("story-part").classList.add("d-none-md");
    document.getElementById("featuring").classList.add("d-none-md");
    document.getElementById("home-tab-icon").classList.add("inactive-tab");
    document.getElementById("story-tab-icon").classList.add("inactive-tab");
    document.getElementById("char-tab-icon").classList.add("inactive-tab");
    document.getElementById("info-tab-icon").classList.remove("inactive-tab");
  }
}
