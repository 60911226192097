import { Controller } from "stimulus"


export default class extends Controller {
  static targets = [
    "message",
    "nextButton",
    "phoneWrapper",
    "choiceWrapper",
    "choiceInnerWrapper",
    "typingIndicator",
    "modalBackground",
    "postStoryPanel",
    "confirmWarningModal",
    "feedbackModal",
    "feedbackEmoji"
  ]

  initialize() {
    if (!this.showWarnings) {
      this.showMessagesUntilCurrentIndex()
      this.zoomOutPhoneOnBlur()
    }
  }

  connect() {
    this.boundLogLeaveTime = this.logLeaveTime.bind(this)
    document.addEventListener('visibilitychange', this.boundLogLeaveTime)
    if (this.showWarnings) {
      this.modalBackgroundTarget.classList.remove('hidden');
      this.nextButtonTarget.setAttribute("style", "display: none")
    }
  }

  disconnect() {
    document.removeEventListener('visibilitychange', this.boundLogLeaveTime)
  }

  next() {
    this.zoomPhone();
    this.index++
  }

  previous() {
    this.index--
  }

  logLeaveTime() {
    if (document.visibilityState === 'hidden') {
      const dateTime = new Date().toLocaleString('en-US', {
                                                  timezone: 'UTC',
      })
      console.log(dateTime)
    }
  }

  showMessagesUntilCurrentIndex() {
    this.nextButtonTarget.classList.add("hidden")
    this.messageTargets.forEach((el, i) => {
      if (i <= this.index) {
        this.showMessageOrChoice(el)
        if (i == this.index) {
          if (el.textContent.trim().toLowerCase() === "the end") {
            this.zoomOutPhone() 
            el.scrollIntoView()
            this.showFeedbackModal()
            this.nextButtonTarget.classList.add('hidden')
          }
          else if (el.classList.contains('nodelay')) {
            this.index++
          }
          else {
          }
        }

      }
      // Peek ahead to see if we should advance
      else if (i == this.index + 1) {
        //Advance received
        if (el.classList.contains('received')) {
          this.typingIndicatorTarget.classList.remove('hidden');
          this.typingIndicatorTarget.scrollIntoView()
          window.setTimeout(() => {
            this.index++
          }, 1600)
        }
        else {
           window.setTimeout(() => {
             this.nextButtonTarget.classList.remove('hidden')
           }, 1000)
        }
      }
      else {
        el.classList.add("hidden")
      }
    })
  }

  showMessageOrChoice(el) {
    //check if it is a choice type
    if (el.classList.contains('choice')) {
      //Only show a choice if it has not been made yet
      if (el.dataset.choiceCompleted !== "completed") {
        this.modalBackgroundTarget.classList.remove('hidden')
        el.dataset.choiceCompleted = "completed"
        var clonedElem = el.cloneNode(true);
        this.choiceWrapperTarget.classList.remove('hidden');
        this.choiceInnerWrapperTarget.replaceChildren(clonedElem);
        clonedElem.classList.remove('hidden')
        clonedElem.scrollIntoView()
      }
      else {
        this.modalBackgroundTarget.classList.add('hidden');
        this.choiceWrapperTarget.classList.add('hidden');
        el.classList.add('hidden')
      }
    }
    else { //Not a choice
      this.modalBackgroundTarget.classList.add('hidden')
      if (el.classList.contains('hidden')) { //First time
        if (el.classList.contains('received')) {
          this.showMessageWithDelay(el)
        }
        else {
          this.typingIndicatorTarget.classList.add('hidden')
          this.showMessage(el)
        }

      }
    }
  }

  async showMessageWithDelay(el) {
    this.typingIndicatorTarget.classList.remove('hidden')
    this.typingIndicatorTarget.scrollIntoView()
    if (el.classList.contains('suspense')) {
      window.setTimeout(() => {
        this.typingIndicatorTarget.classList.add('hidden')
        window.setTimeout(() => {
          this.typingIndicatorTarget.classList.remove('hidden')
          this.typingIndicatorTarget.scrollIntoView()
          window.setTimeout(() => {
            this.showMessage(el)
            this.typingIndicatorTarget.classList.add('hidden')
            this.nextButtonTarget.removeAttribute('disabled')
          }, 100 * Math.random())
        }, 500)
      }, 400)
    }
    else {
       window.setTimeout(() => {
         this.typingIndicatorTarget.classList.add('hidden')
         this.showMessage(el)
       }, 400)
    }
  }

  showMessage(el) {
    el.classList.remove("hidden")
    el.scrollIntoView()
  }

  get index() {
    return parseInt(this.data.get("index"))
  }

  get showWarnings() {
    return this.data.get("warnings") === "true"
  }

  set index(value) {
    this.data.set("index", value)
    this.showMessagesUntilCurrentIndex()
  }

  showFeedbackModal() {
    this.feedbackModalTarget.classList.remove('hidden')
    this.feedbackModalTarget.scrollIntoView()
    this.nextButtonTarget.setAttribute("style", "display: none")
  }

  giveFeedback(event) {
    const feedbackEmoji = event.target.innerText
    this.feedbackEmojiTarget.value = feedbackEmoji
    this.nextButtonTarget.classList.add('hidden')
  }

  continueWarning() {
    this.confirmWarningModalTarget.classList.add('hidden');
    this.nextButtonTarget.removeAttribute("style", "display: none")
    this.modalBackgroundTarget.classList.add('hidden');
    this.showMessagesUntilCurrentIndex();
  }

  zoomPhone(){
    let backdrop = document.getElementById("backdrop-phone")
    let phoneZoom = document.getElementById("phone-up")
    let mlAuto = document.getElementById("custom-margin-left")
    let customStart = document.getElementById("custom-start")
    let addOut = document.getElementById("phone-up")
 

    backdrop.classList.add('show')
    backdrop.classList.remove('out');
    phoneZoom.classList.add('phone-wrapper-zoom')
    addOut.classList.remove('delay');
    customStart.classList.add('justify-start-custom')
  }


  zoomOutPhoneOnBlur() {
    let backdrop = document.getElementById("backdrop-phone")
    let zoomFn = this.zoomOutPhone

    window.addEventListener('click', function (e) {
      if (e.target == backdrop)  {
        zoomFn()
      }
    });
  }

  zoomOutPhone() {
    let backdrop = document.getElementById("backdrop-phone")
    let phoneZoom = document.getElementById("phone-up")
    let mlAuto = document.getElementById("custom-margin-left")
    let addOut = document.getElementById("phone-up")

    backdrop.classList.remove('show');
    backdrop.classList.add('out');
    phoneZoom.classList.remove('phone-wrapper-zoom');
    addOut.classList.add('delay');
    mlAuto.classList.remove('ml-auto');
  }
 }
