// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
import { Controller } from "stimulus";

export default class extends Controller {

  static targets = [
    'container',
    'buttonExtractUsers',
  ];

  connect() {
  }

  disconnect() {
    console.log('Account edit component disconnected');
  }

}
