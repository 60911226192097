import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["new-bug"];

  connect() {
  }

  disconnect() {
  }

  closeModal() {
    document.getElementById("new-bug").classList.add('hidden');
  }

}