// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
import { Controller } from "stimulus";

export default class extends Controller {

  userId = null;

  static targets = [
    'container',
  ];

  connect() {
    this.userId = $(this.containerTarget).data('userid');
  }

  disconnect() {
  }

  toggleDrawerMenu() {
    const container = $(this.containerTarget).find('.left-draw-menu-wrapper');
    if (container.hasClass('opened')) {
        container.removeClass('animate');
        container.addClass('animate-backward');
        setTimeout(() => {
            container.removeClass('opened');
        }, 250, container);
    } else {
        container.addClass('opened');
        container.removeClass('animate-backward');
        container.addClass('animate');
    }
  }
}
