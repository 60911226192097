// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container",
                    "dropdownNav",
                    "menuArrowUp",
                    "menuArrowDown",
                    "dropdownLink"];

  connect() {
    console.log("All programs component connected");
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
      $(".section-content")
      .not(".slick-initialized")
      .slick({
        arrows: true,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        mobileFirst: true,
        infinite: false,
        autoplay: false,
        draggable: true,
        touchMove: true,
        dots: false,

        responsive: [
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 1536,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1,
            },
          },
        ],
      });
    }
    $( document ).ready(function() {
        if(window.location.hash) {
          var targetLoc = $(window.location.hash)
          window.scrollTo(0, (targetLoc.offset().top -50));
        }
    });
  }

  disconnect() {
    console.log("All programs component disconnected");
  }

  toggleDrawerMenu() {}

  scrollToAnchor() {
    const element = event.target
    const e = element.dataset.allprogramsId
    const el = document.getElementById(e)
    el.scrollIntoView()
    this.dropdown()
  }

  scrollToTop() {
    const el = document.getElementById("menu-button")
    el.focus()
  }

  dropdown() {
    if(this.dropdownNavTarget.classList.contains("hidden")) {
      this.dropdownNavTarget.classList.remove("hidden")
      this.menuArrowDownTarget.classList.add("hidden")
      this.menuArrowUpTarget.classList.remove("hidden")
    } else {
      this.dropdownNavTarget.classList.add("hidden")
      this.menuArrowUpTarget.classList.add("hidden")
      this.menuArrowDownTarget.classList.remove("hidden")
    }
    
  }
}
