import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $('.onboarding-carousel').not('.slick-initialized').slick({
      arrows: false,
      infinite: false,
      dots: true,
      appendDots: $('.dots')
    }).on( 'afterChange', function( event, slick, currentSlide ) {
      $.each(slick.$dots, (i, el) => {
        $(el).find('li').eq(currentSlide).addClass('slick-active').find('button');
      })
    })
  }

  disconnect() {
    $('.onboarding-carousel').slick('unslick');
  }

  next() {
    $('.onboarding-carousel').slick('slickNext');
  }
}